'use client';

import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Link, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TvIcon from '@mui/icons-material/Tv';
import DialogBoxEditScreen from '../components/EditScreen/DialogBoxEditScreen';
import { SCREEN_WARIANTS } from '../components/EditScreen/types';
import { Orientation } from '../components/EditScreen/types';
import Templates from "../components/EditScreen/templatesSVG/index";

const DialogBoxSplitScreen = ({ isOpen, onClose, onTakeData, isIcon }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // ---- useStates
    const [openEditScreen, setOpenEditScreen] = React.useState(false);
    const [rectanglesUnfolding, setRectanglesUnfolding] = React.useState([]);
    const [orientation, setOrientation] = React.useState(Orientation.LANDSCAPE);
    console.log("DialogBoxSplitScreen -> openEditScreen", openEditScreen)
    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={isOpen}
            onClose={() => onClose(false)}
        >
            <DialogBoxEditScreen isOpen={openEditScreen} orientation={orientation} onClose={(close) => setOpenEditScreen(close)} rectanglesUnfolding={rectanglesUnfolding} />
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" backgroundColor={"#1A1A1A"}>
                {/* <img src={"./images/postermywallLogo.png"} alt="Logo" width={"150px"} /> */}
                <TvIcon sx={{ fontSize: 80 }} />
                <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>{t("views.dialogBoxSplitScreen.title")}</DialogTitle>
                <div style={{
                    width: "100%",
                    backgroundColor: "#e0e0e0",
                    height: "1px",
                }}></div>
            </Box>
            <DialogContent sx={{ minHeight: "30vh" }} >
                <Grid container spacing={2} justifyContent="center" style={{ width: '100%', margin: "auto" }}>
                    <Grid item container spacing={2} >
                        {SCREEN_WARIANTS.map((item, index) => (
                            <Grid item xs={3} key={index}>
                                <SplitScreenElement TemplateIcon={Templates[index]} title={item.text} orientation={item.orientation} setOrientation={setOrientation} idx={index} setOpenEditScreen={setOpenEditScreen} rectanglesUnfolding={item.rectangles} setRectanglesUnfolding={setRectanglesUnfolding} />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </DialogContent>

        </Dialog>
    )
}

export default DialogBoxSplitScreen


// ------------- ELEMENT FOR MAPPING ----------------
export const SplitScreenElement = ({ TemplateIcon, title, orientation, setOrientation, idx, setOpenEditScreen, rectanglesUnfolding, setRectanglesUnfolding }) => {
    return (
        <div
            key={idx}
            onClick={() => {
                console.log("clicked element")
                setOrientation(orientation)
                setRectanglesUnfolding(rectanglesUnfolding)
                setOpenEditScreen(true)
            }}
            style={{
                width: "160px",
                height: "110px",
                border: "1px solid white",
                marginTop: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer"
            }}>
            <div style={{
                margin: "auto"
            }}>
                <TemplateIcon width={100} height={50} fill="blue" />
                {/* <TvIcon  sx={{ fontSize: 60 }} /> */}
            </div>
            <div style={{
                width: "100%",
                backgroundColor: "white",
                height: "1px",
            }}></div>
            <div style={{
                margin: "auto"
            }}>
                {title}
            </div>
        </div>
    );
}